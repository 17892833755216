import { capitalize, getCapitalizedWordsFromURLPath } from './stringHelpers';
import {
  REFERRAL_APPT_TYPE,
  FOLLOW_UP_APPT_TYPE,
  ONBOARDING_APPT_TYPE,
} from '../constants/appointment';
import { ROUTES } from '../constants/routes';

const isNameRoutePath = (path) => {
  // eslint-disable-next-line
  const namePathNameRegex = /^(\/name\/)((?:[a-z0-9\-]+))$/g;
  return namePathNameRegex.test(path);
};

export const getPageNameByRoutePath = (routePath) => {
  // eslint-disable-next-line
  let pageName = '';
  routePath = `/${routePath.split('/')[1]}`;

  switch (routePath) {
    case ROUTES.PIM_ID:
      pageName = 'PimId';
      break;
    case ROUTES.EMAIL:
      pageName = 'Contact Information';
      break;
    case ROUTES.GENDER:
      pageName = 'Sex';
      break;
    case ROUTES.PRIVACY_POLICY:
      pageName = 'Privacy';
      break;
    case ROUTES.STREET_ADDRESS:
      pageName = 'Address';
      break;
    case ROUTES.ADDRESS:
      pageName = 'Confirm Address';
      break;
    case ROUTES.BIRTHDATE:
      pageName = 'DOB';
      break;
    case ROUTES.HEALTH_PLAN:
      pageName = 'Health Plan';
      break;
    case ROUTES.CURRENT_PARTNERS:
      pageName = 'Employer Page';
      break;
    case ROUTES.NEW_PARTNER:
      pageName = 'Employer Not Listed';
      break;
    case ROUTES.FEDERAL_PLAN:
      pageName = 'Medicare/Medicaid';
      break;
    case ROUTES.SELF_PAY:
      pageName = 'Health Plan Not Listed';
      break;
    case ROUTES.GET_SYMPTOM_RELIEF:
      pageName = 'Add Symptoms';
      break;
    case ROUTES.FIRST_APPT_BOOK:
      pageName = 'Calendar';
      break;
    case ROUTES.FIRST_APPT_CONFIRM:
      pageName = 'Review Appointment Details';
      break;
    case ROUTES.FIRST_APPT_OVERVIEW:
      pageName = 'App Download';
      break;
    case ROUTES.OFF_BOARDING:
      pageName = 'Offboarding';
      break;
    case ROUTES.SIGN_IN:
      pageName = 'Sign In';
      break;
    case ROUTES.RESET_PASS:
      pageName = 'Reset Password';
      break;
    case ROUTES.RESET_PASS_EMAIL:
      pageName = 'Reset Password Email';
      break;
    case ROUTES.RESET_PASS_CODE:
      pageName = 'Reset Password Code';
      break;
    case ROUTES.INSURANCE_INFO:
      pageName = 'Insurance Information';
      break;
    case ROUTES.INSURANCE_CARD_SCAN:
      pageName = 'Insurance Card Take Picture';
      break;
    case ROUTES.INSURANCE_CARD_UPLOAD:
      pageName = 'Insurance Card Image Upload';
      break;
    case ROUTES.POLICY_HOLDER:
      pageName = 'Policy Holder';
      break;
    case ROUTES.NON_POLICY_HOLDER:
      pageName = 'Policy Holder Relationship';
      break;
    case ROUTES.NETWORK_ERROR:
      pageName = 'Network Error';
      break;

    default: {
      const [, firstLetter, ...pathRest] = routePath;
      const fullPath = firstLetter.toUpperCase() + pathRest.join('');
      if (isNameRoutePath(routePath)) {
        const [nameArg] = fullPath.split('/');
        const firstPathArg = capitalize(nameArg);
        pageName = `${firstPathArg}`;
      } else {
        pageName = getCapitalizedWordsFromURLPath(fullPath);
      }
      break;
    }
  }
  return pageName;
};

export const getPageNameSegmentTokenByRoutePath = (routePath) => {
  let segmentToken = '';
  routePath = `/${routePath.split('/')[1]}`;

  switch (routePath) {
    case ROUTES.EMAIL:
      segmentToken = 'CONTACT_INFORMATION';
      break;
    case ROUTES.GENDER:
      segmentToken = 'SEX';
      break;
    case ROUTES.PRIVACY_POLICY:
      segmentToken = 'PRIVACY';
      break;
    case ROUTES.STREET_ADDRESS:
      segmentToken = 'ADDRESS';
      break;
    case ROUTES.ADDRESS:
      segmentToken = 'CONFIRM_ADDRESS';
      break;
    case ROUTES.BIRTHDATE:
      segmentToken = 'DOB';
      break;
    case ROUTES.GET_SYMPTOM_RELIEF:
      segmentToken = 'GET_SYMPTOM_RELIEF';
      break;
    case ROUTES.FIRST_APPT_BOOK:
      segmentToken = 'CALENDAR';
      break;
    case ROUTES.HEALTH_PLAN:
      segmentToken = 'HEALTH_PLAN';
      break;
    case ROUTES.CURRENT_PARTNERS:
      segmentToken = 'EMPLOYER_PAGE';
      break;
    case ROUTES.NEW_PARTNER:
      segmentToken = 'EMPLOYER_NOT_LISTED';
      break;
    case ROUTES.FEDERAL_PLAN:
      segmentToken = 'MEDICARE_MEDICAID_MASSHEALTH';
      break;
    case ROUTES.FIRST_APPT_CONFIRM:
      segmentToken = 'REVIEW_APPT_DETAILS';
      break;
    case ROUTES.FIRST_APPT_OVERVIEW:
      segmentToken = 'APPT_BOOKED';
      break;
    case ROUTES.SIGN_IN:
      segmentToken = 'SIGN_IN';
      break;
    case ROUTES.RESET_PASS:
      segmentToken = 'RESET_PASS';
      break;
    case ROUTES.RESET_PASS_EMAIL:
      segmentToken = 'RESET_PASS_EMAIL';
      break;
    case ROUTES.RESET_PASS_CODE:
      segmentToken = 'RESET_PASS_CODE';
      break;
    case ROUTES.INSURANCE_INFO:
      segmentToken = 'INSURANCE_INFORMATION';
      break;
    case ROUTES.INSURANCE_CARD_SCAN:
      segmentToken = 'INSURANCE_CARD_TAKE_PICTURE';
      break;
    case ROUTES.INSURANCE_CARD_UPLOAD:
      segmentToken = 'INSURANCE_CARD_IMAGE_UPLOAD';
      break;
    case ROUTES.POLICY_HOLDER:
      segmentToken = 'POLICY_HOLDER';
      break;
    case ROUTES.NON_POLICY_HOLDER:
      segmentToken = 'POLICY_HOLDER_RELATIONSHIP';
      break;
    case ROUTES.NETWORK_ERROR:
      segmentToken = 'NETWORK_ERROR';
      break;

    default: {
      const [, firstLetter, ...pathRest] = routePath;
      const suffixString = pathRest.join('').replace(/-+/g, '_').toUpperCase();
      if (isNameRoutePath(routePath)) {
        const [nameArg] = (firstLetter + suffixString).split('/');
        segmentToken = nameArg.toUpperCase();
      } else {
        segmentToken = firstLetter.toUpperCase() + suffixString;
      }
      break;
    }
  }

  return segmentToken;
};

const APPOINTMENT_TYPE_NAME = {
  [FOLLOW_UP_APPT_TYPE]: `Follow-Up Appointment`,
  [ONBOARDING_APPT_TYPE]: 'Signup',
  [REFERRAL_APPT_TYPE]: capitalize(REFERRAL_APPT_TYPE),
};

export const parsedAppointmentType = (type) => {
  return APPOINTMENT_TYPE_NAME[type];
};
