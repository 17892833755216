import React from 'react';
import { useHistory } from 'react-router-dom';

import usePartnersAPI from './usePartnersAPI';
import { localStorageGet, localStorageSave } from '../utils/localStorageHelper';
import { ROUTES } from '../constants/routes';
import { CURRENT_PARTNERS } from '../constants/subscriptionStatus';

/**
 * @function useCommonPartnersUILogic
 * @description Custom hook that shares common functionalities for partner-based UI pages.
 * It handles fetching current Oshi partners list to parse and display them as UI components,
 * also handles different UI events to navigate and pass data into different new UI pages
 * related to generic sign-flow.
 * @param {(state: any): void} setIsFetching - Redux action function to show/hide loading mask screen
 * @param {(list: Partner[]): PartnerUI[]} parseList - Parse-data based function to format partner list for UI
 * @returns {{
 *   partners: PartnerUI[],
 *   selectedPartner: PartnerUI | null,
 *   animationDirection: 'mount' | 'unmount',
 *   setAnimationDirection: (state: string): void,
 *   handleSelectPartnerClick: (id: string): void,
 *   handleNewPartnerBtnClick: (): void,
 *   handleContinueBtnClick: (): void
 * }}
 */
const useCommonPartnersUILogic = ({
  setIsFetching,
  parseList,
  updatePatientLead,
  isFirstHealthPlanPartnerById,
  findSelectedPartnerByList,
}) => {
  const history = useHistory();
  const [animationDirection, setAnimationDirection] = React.useState('mount');
  const [partnerList, setPartnerList] = React.useState([]);
  const [payerList, setPayerList] = React.useState([]);
  const [selectedPartner, setSelectedPartner] = React.useState(null);
  const { getPartnersList, getPayersList } = usePartnersAPI();
  const { isReferringProvider } = localStorageGet('isReferringProvider');
  const { partner: referringPartner } = localStorageGet('partner');
  const state = localStorageGet('state');
  const { address } = localStorageGet('address');
  const fromLS = localStorageGet('selectedPartner') || null;

  React.useLayoutEffect(() => {
    if (isReferringProvider) {
      const payers = payerList.reduce((acc, val) => {
        acc[val.system_partner_id] = { partner_id: val.partner_id };
        return acc;
      }, {});

      const list = partnerList
        .filter((item) => Object.keys(payers).includes(item.system_partner_id))
        .map((item) => ({ ...item, ...payers[item.system_partner_id] }));

      list.forEach((item) => {
        if (item.system_partner_id === CURRENT_PARTNERS.UHC) {
          list.push({
            ...item,
            system_partner_id: CURRENT_PARTNERS.UHC_OXFORD,
          });
        }
      });

      setPartnerList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerList]);

  React.useLayoutEffect(() => {
    if (payerList.length === 1) {
      setIsFetching(true);
      localStorageSave('selectedPartner', selectedPartner);

      handleContinueBtnClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner]);

  const memoizedPartnerList = React.useMemo(() => {
    return parseList(partnerList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerList]);

  const foundPartner = React.useMemo(
    () => memoizedPartnerList.find((p) => p.value === fromLS?.selectedPartner),
    [fromLS, memoizedPartnerList]
  );

  React.useLayoutEffect(() => {
    setIsFetching(true);

    (async () => {
      setIsFetching(true);
      const partList = await getPartnersList();
      setPartnerList(partList);

      if (isReferringProvider) {
        const payList = await getPayersList(referringPartner);
        setPayerList(payList);

        if (payList.length === 1) {
          setSelectedPartner({
            selectedPartner: payList[0].system_partner_id,
            bill_to_sf_id: payList[0].partner_id,
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    if (
      (partnerList.length > 1 &&
        memoizedPartnerList.length >= 1 &&
        !isReferringProvider) ||
      (payerList.length > 1 && isReferringProvider)
    ) {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerList, memoizedPartnerList]);

  React.useLayoutEffect(() => {
    setSelectedPartner(foundPartner && fromLS ? fromLS : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPartner]);

  const handleContinueBtnClick = React.useCallback(async () => {
    try {
      setIsFetching(true);

      const partner =
        selectedPartner?.selectedPartner === CURRENT_PARTNERS.UHC_OXFORD
          ? CURRENT_PARTNERS.UHC
          : selectedPartner?.selectedPartner;
      const isFirstHealthPlanPartner = isFirstHealthPlanPartnerById(partner);

      if (isFirstHealthPlanPartner || partner === CURRENT_PARTNERS.WAIVER) {
        history.push({
          pathname: ROUTES.FEDERAL_PLAN,
          state: {
            partnerList,
          },
        });
        setIsFetching(false);
        return;
      }

      if (partner === CURRENT_PARTNERS.OTHER) {
        history.push({
          pathname: ROUTES.CURRENT_PARTNERS,
        });
        setIsFetching(false);
        return;
      }

      const foundPartner = findSelectedPartnerByList(partnerList, partner);

      const foundPartnerState =
        foundPartner?.operatingStates?.includes(state) || null;

      const chosenPartner = isReferringProvider ? referringPartner : partner;

      if (!foundPartnerState) {
        history.push({
          pathname: ROUTES.OFF_BOARDING,
          state: {
            address: {
              ...address,
              stateName: state,
            },
            partner: {
              id: chosenPartner,
              name: foundPartner.display_name,
              notFoundState: true,
            },
          },
        });
        setIsFetching(false);
        return;
      }

      await updatePatientLead({ partner: chosenPartner, address });
      setIsFetching(false);
      setAnimationDirection('unmount');
    } catch (e) {
      console.log('error', e);
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selectedPartner, partnerList]);

  const handleNewPartnerBtnClick = () => {
    if (isReferringProvider) {
      history.push(ROUTES.SELF_PAY);
      return;
    }

    history.push({
      pathname: ROUTES.NEW_PARTNER,
      state: {
        address: {
          ...address,
          stateName: state,
        },
      },
    });
  };

  const handleSelfPayBtnClick = () => {
    history.push({
      pathname: ROUTES.SELF_PAY,
      state: {
        address: {
          ...address,
          stateName: state,
        },
      },
    });
  };

  const handleSelectPartnerClick = ({ id, value, item }) => {
    const partnerId = value;

    const partnerState = isReferringProvider
      ? {
          selectedPartner: partnerId,
          bill_to_sf_id: item.partner_id,
        }
      : {
          selectedPartner: partnerId,
        };

    if (partnerId !== CURRENT_PARTNERS.OTHER) {
      localStorageSave('selectedPartner', partnerState);
    }

    setSelectedPartner(partnerState);
  };

  return {
    partners: memoizedPartnerList,
    selectedPartner,
    animationDirection,
    setAnimationDirection,
    handleSelectPartnerClick,
    handleNewPartnerBtnClick,
    handleContinueBtnClick,
    handleSelfPayBtnClick
  };
};

export default useCommonPartnersUILogic;
